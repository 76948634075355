<template>
  <b-card :title="'Logs view for &quot;'+script.name+'&quot; with run id '+script_run_id" class="col-12" v-if="tabactive>=0">
    <b-tabs card lazy v-model="tabactive">
    <b-tab :key="idx" v-for="(tab,idx) in tabs" :title="tab.loglevel+' ('+tab.count+')'" :active="tab==tabactive">
      <b-pagination
      v-if="tab.count>tab.per_page"
      v-model="curpage"
      :total-rows="tab.count"
      :per-page="tab.per_page"
      align="center"
    ></b-pagination>
      <b-table-lite :items="log_rec" :tbody-tr-class="rowClass" v-if="log_rec">
      <template v-slot:table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{width: (field.key === 'loglevel' || field.key === 'time') ? '8%' : null, }"
        />
      </template>
      <template v-slot:cell(message)="data">
        <a :href="data.item.href">{{ data.item.message }}</a>
        <span class="table-warning">{{data.item.changed}}</span>
      </template>
      <template v-slot:cell(traceback)="data">
        <pre>{{ data.item.traceback }}</pre>
      </template>
    </b-table-lite>
    <b-pagination
      v-if="tab.count>tab.per_page"
      v-model="curpage"
      :total-rows="tab.count"
      :per-page="tab.per_page"
      align="center"
    ></b-pagination>
    </b-tab>
   </b-tabs>
    <span v-if="log_rec && log_rec.length==0">No log records for script run {{script_run_id}} registered</span>
  </b-card>
</template>

<script>
export default {
  props: ["script_run_id","script"],
  data() {
    return {
      log_rec: null,
      tabs: [],
      tabactive: 0,
      curpage: 1
    };
  },
  watch: {
    tabactive: function(oldV,newV){if (newV!=-1){this.curpage=1; this.load_log();}},
    curpage: function(){this.load_log()}
  },
  methods: {
    load_tabs() {
      this.backend.get("/scripts/logs/" + this.script_run_id).then(resp => {
        if (resp.data) {
          this.tabs=resp.data
          this.tabactive = 0;
          this.load_log();
        }
      });
    },
    load_log() {
      if (this.tabactive==-1) {return;}
      this.log_rec = null;
      this.backend.get("/scripts/logs/" + this.script_run_id+"/"+this.tabs[this.tabactive].loglevel+"/"+this.curpage).then(resp => {
        if (resp.data) {
          let file_id_regex = /\sfile_id=(\d+)\s/;
          // little preparing of
          this.log_rec = resp.data.map(x=>{
            // CHANGE type
            if (x.loglevel=='CHANGE') {
              x['_cellVariants']={'loglevel':'warning'}
              if (x.from_obj && typeof(x.from_obj)=='object') 
              {
               x.traceback=[]
               Object.keys(x.from_obj).forEach(key=>{
                 // in case of objects some values only can differ. so additional check is needed
                 if (!x.from_obj[key] || typeof(x.from_obj[key])!='object')
                   {
                     if (x.from_obj[key]!=x.to_obj[key])
                      {x.traceback.push(key+": '"+x.from_obj[key] + "' -> '" + x.to_obj[key] + "'")}
                  }
                 else
                  {
                    // by id
                    if (x.from_obj[key]['id'] && typeof(x.to_obj[key])=='number')
                     {
                       if (x.from_obj[key]['id']!=x.to_obj[key])
                         {x.traceback.push(key+": '"+x.from_obj[key]['id'] + " ("+x.from_obj[key]['title'] + ")' -> '" + x.to_obj[key] + "'")}
                     }
                    // by value
                    else if (x.from_obj[key]['value'])
                     {
                       if (x.from_obj[key]['value']!=x.to_obj[key])
                        {x.traceback.push(key+": '"+x.from_obj[key]['value'] + " ("+x.from_obj[key]['title'] + ")' -> '" + x.to_obj[key] + "'")}
                     }
                    // by title
                    else if (x.from_obj[key]['title'])
                     {
                       if (x.from_obj[key]['title']!=x.to_obj[key])
                        {x.traceback.push(key+": '"+x.from_obj[key]['title'] + " -> '" + x.to_obj[key] + "'")}
                     }
                  }
               }) 
              } else {
                x.traceback = "'"+x.from_obj + "' -> '" + x.to_obj + "'"
              }
            }
            else if (x.loglevel=='CREATE') {x.traceback = x.to_obj}
            else if (x.loglevel=='DELETE') {x.traceback = x.from_obj}
            //add links to File
            let fmatch = x.message.match(file_id_regex)
            if (fmatch) {x['href']='/files/'+fmatch[1]}
            // remove not needed
            delete x.from_obj
            delete x.to_obj

            return x
          });
        }
      });
    },
    rowClass(item) {
        if (item && (item.loglevel === 'ERROR' || item.loglevel === 'CRITICAL'))
          return 'rowError'
        if (item && (item.loglevel === 'INFO' && item.message.substr(0,8) === 'Creating'))
          return 'rowSuccess'
        return 'rowClass'
      }
  },
  created() {
    this.load_tabs();
  }
};
</script>

<style scoped>
pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
    margin: 0px;
}
</style>